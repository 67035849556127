//
// Override global variables
//

// Theme colors
$primary:       									#76CD17;
$primary-hover:    									#CFFF9B;
$primary-light: #eaeaea;
$primary-inverse:  									#FFFFFF;

$info:       									    #76CD17;
$info-light:    									#E1E9FF;
$info-hover:    									#CFFF9B;
$info-inverse:  									#FFFFFF;

$success:       									#76CD17;
$success-hover:    									#CFFF9B;
$success-light:    									#E1F0FF;
$success-inverse:  									#FFFFFF;
