.splash-spinner .path {
  stroke: $primary !important;
}

h1{
  font-weight: bold;
  font-size: 30px;
  margin: 20px 0px;
}

h2{
  margin: 30px 0px;
}

.list-documnetation{
  margin-bottom: 16px;
  padding-left: 16px !important;
  list-style: none;
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  display: block;

  li{
    margin-bottom: 16px;
    position: relative;
  }

  li:before{
    content: '•';
    display: inline-block;
    padding-right: 20px;
    height: 10px;
    width: 10px;
  }
}


.MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6 {
  margin: 20px 0px;
}

.img-size{

  img{
    width: 100%;
  }

}

.aside-secondary-enabled .aside{
  width: 100px;
}

.aside-secondary-enabled.aside-fixed .wrapper {
  padding-left: 0 !important;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  padding: 0 25px 0 140px;
}

.container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  max-width: 100%;
}


@include media-breakpoint-down(lg) {
  width: 48%;
}

@include media-breakpoint-down(md) {
  width: 50%;
}

@include media-breakpoint-down(sm) {

  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    padding: 0 25px;
  }
}


nav.MuiList-root.makeStyles-root-1.MuiList-padding.MuiList-subheader {
  margin-left: -40px;
}



.dashboard-main{
  width: 100%;

  .dashboard-block{
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .dashboard-block-item{
      padding: 20px;
      width: 32%;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 8px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);

      hr{
        margin-bottom: 20px;
      }

    .dashboard-block-item-top{
      margin: 20px 0px;

      .img-integrations{
        margin-bottom: 30px;

        img{
          max-height: 30px;
        }
      }

      p{
        margin: 0;
      }

      i{
        font-size: 32px !important;
        color: $primary;
      }

    }

    .dashboard-block-item-bottom{

      i{
        margin-right: 5px;
      }

    }

      @include media-breakpoint-down(lg) {
        width: 48%;
      }

      @include media-breakpoint-down(md) {
        width: 50%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

    }

    .dashboard-block-item-uses-cases{
      padding: 20px;
      width: 32%;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 8px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);

      hr{
        margin-bottom: 20px;
      }

      .dashboard-block-item-top{
        margin: 0px 0px;

        p{
          margin: 0;
          color: $primary-light;
        }

        .sub-title{
          font-weight: bold;
          color: $primary;
          font-size: 16px;
        }

        h2{
          font-weight: 200;
        }

      }

      .dashboard-block-item-bottom{

        i{
          margin-right: 5px;
          color: $primary;
          font-size: 13px;
        }

      }

      @include media-breakpoint-down(md) {
        width: 50%;
      }

      @include media-breakpoint-down(lg) {
        width: 48%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

    }

    .android-block{
      width: 100%;
      display: flex;
      margin-bottom: 20px;

      .dashboard-block-item-sdks{
        padding: 20px;
        width: 48%;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);

        .dashboard-block-item-top{
          margin: 0px 0px;
          display: flex;
          align-self: center;

          .image {
            align-self: center;
            margin: 0px 20px;
          }

          .titles{
            margin: 20px 0px;

            p{
              margin: 0;
            }
          }

          img{
            max-width: 30px;
          }

          h2{
            font-weight: 200;
          }

        }

        .dashboard-block-item-bottom{

          i{
            margin-right: 5px;
            color: $primary;
            font-size: 13px;
          }

        }

        @include media-breakpoint-down(md) {
          width: 50%;
        }

        @include media-breakpoint-down(lg) {
          width: 48%;
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
        }

      }


    }

  }

}




//Api Reference Style
.apireference {
  display: flex;

  .api-ref-info-container {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 28px;

    article {
      padding: 14px 0 30px 0;

      h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;
      }

      h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin: 30px 0 0 0;
      }

      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        margin: 0 0 10px 0;
      }

      h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        margin: 0 0 8px 0;
      }
    }


    table {
      margin: 40px 0 40px 0;
      border-radius: 10px;
      width: 100%;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    th {
      background-color: $primary-light;
      color: #404040;
      text-align: left;
      font-weight: 700;
      border: #e0e0e0 1px solid;
      line-height: 1.5rem;
      padding: 16px;

    }

    td {
      padding: 20px 10px 20px 10px;
      border: #e0e0e0 1px solid;
      font-size: 12px;
    }

    .label {
      display: inline;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 50px;
    }

    span.label.label-optional {
      width: auto;
      padding: 6px;
    }

    .label-optional {
      float: right;
      background-color: grey;
      margin-top: 4px;
    }

    .label-new {
      background-color: $primary !important;
      margin-top: 4px;
    }

    #generator .content {
      color: #b0b0b0;
      border-top: 1px solid #ebebeb;
      padding: 10px 0;
    }

    .label-optional {
      float: right;
      background-color: grey;
      margin-top: 4px;
    }

    code {
      background-color: #f3f6f900;
    }

    pre .pln, code .pln {
      color: #fff;
      background-color: #f3f6f900;
    }

    pre.prettyprint, code.prettyprint {
      background-color: #404040;
      border-radius: 8px;
      margin: 20px 0 20px 0;
      padding: 20px 10px;
    }

    .type {
      font-weight: 600;
      font-size: 15px;
      display: inline-block;
      margin: 0 0 5px 0;
      padding: 4px 5px;
      border-radius: 6px;
      text-transform: uppercase;
      background-color: #3387CC;
      color: #ffffff;
    }

    .type__get {
      background-color: $primary !important;
    }

    .type__put {
      background-color: #e5c500;
    }

    .type__post {
      background-color: #4070ec;
    }

    .type__delete {
      background-color: #ed0039;
    }

    .type__error {
      background-color: #CC0000;
    }

    .type__push {
      background-color: #72BA3A;
    }

    .type__entity {
      background-color: #FFA500;
    }

    .type__socket {
      background-color: #6e4893;
    }

    .type__socket_emit {
      background-color: #6D9348;
    }
  }
}



